<template>
  <section class="first-block" :class="{ 'desktop': !isMobile }">
    <div class="container">
      <div class="first-block-top d-md-flex d-sm-block align-items-start justify-content-between">
        <ul class="list-unstyled d-flex align-items-center phone">
          <li class="phone__item">
            <a aria-label="Whatsapp link" href="https://api.whatsapp.com/send?phone=+37444999585" target="_blank"
              rel="noreferrer">
              <img src="~/assets/images/whatsapp.svg" alt="whatsapp icon" width="18" height="18" data-not-lazy />
            </a>
          </li>
          <li class="phone__item">
            <a aria-label="Viber link" href="viber://chat?number=%2B37444999585" target="_blank" rel="noreferrer">
              <img src="~/assets/images/viber.svg" alt="viber icon" width="18" height="18" data-not-lazy />
            </a>
          </li>
          <li class="phone__item">
            <app-phone-link tel="37444999585" classes="text-decoration-none phone__item__link ellipsis-mode">
              +374 (44) 999-585
            </app-phone-link>
          </li>
        </ul>
        <nuxt-link :to="{ path: localePath(`fix`) }" tag="a" class="pre-fix">
          <span class="aside__item__text text-uppercase" style="font-weight: bold">
            {{ $t('fix.fix') }}
          </span>
        </nuxt-link>
        <div class="d-sm-block d-md-flex">
          <p class="text-md-center rates-title">
            <span class="text-white text-uppercase button">Live</span>
            <span class="fw-bold text-uppercase">Gold, Silver, Platinum Prices</span>
          </p>

          <span class="ms-0 ms-sm-0 ms-md-3 ellipsis-mode current-data">{{ renderCurrentData() }}</span>
        </div>
        <!-- <span v-if="!isSmaillScreen">{{ $t('main.work_hours') }}</span> -->
      </div>
      <!-- <span v-if="isSmaillScreen" class="mobile-work_hours">{{ $t('main.work_hours') }}</span> -->
      <div class="exchanges-container d-grid">
        <client-only>
          <div v-if="tableData && tableData.length">
            <table v-for="(row, index) of tableData" :key="index" class="exchange-table text-uppercase"
              :class="{ 'sample-media': index > 0 }">
              <thead>
                <tr>
                  <th class="exchange-table__title text-start text-truncate metal-media-width">
                    {{ $t('basic.gold') }}
                  </th>
                  <th class="exchange-table__title text-start buy-media-width">
                    <div>{{ $t('basic.buy') }}</div>
                  </th>
                  <th class="exchange-table__title text-start sel-media-width">
                    <div>{{ $t('basic.sell') }}</div>
                  </th>
                  <th class="exchange-table__title text-center text-truncate yestoday-price-media-width">
                    {{ yestodayPrice }}
                  </th>
                  <th :class="{ 'text-end': isMobile }" class="exchange-table__title text-truncate time-media-width">
                    {{ $t('basic.time') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(coll, subItem) of row" :key="subItem" class="gray-background">
                  <td class="exchange-table__item text-truncate">
                    {{ coll.title.replace(/\|/gi, '-') }}
                  </td>
                  <td class="exchange-table__item text-truncate">
                    {{ coll.buy?.toFixed(2) }}
                  </td>
                  <td class="exchange-table__item text-truncate">
                    {{ coll.sell?.toFixed(2) }}
                  </td>
                  <td :class="{
                    'exchange-table__item__red': renderYesterdayPrice(
                      coll.rate_id,
                      coll.sell,
                      coll.yesterday_sell
                    ).isNegative
                  }" class="exchange-table__item exchange-table__item__green text-truncate text-center">
                    {{
                      renderYesterdayPrice(coll.rate_id, coll.sell, coll.yesterday_sell).price
                    }}
                  </td>

                  <td :class="{
                    'exchange-table__item__red': isClosed(),
                  }" class="exchange-table__item text-truncate">
                    <!-- {{ isClosed() ? $t('basic.closed') : currentTime }} -->
                    {{ coll.updated_at }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <table class="exchange-table text-uppercase w-100">
              <thead>
                <tr class="border-bottom">
                  <th class="exchange-table__title text-start text-truncate metal-media-width">
                    {{ $t('basic.gold') }}
                  </th>
                  <th class="exchange-table__title text-start buy-media-width">
                    <div>{{ $t('basic.buy') }}</div>
                  </th>
                  <th class="exchange-table__title text-start sel-media-width">
                    <div>{{ $t('basic.sell') }}</div>
                  </th>
                  <th class="exchange-table__title text-center text-truncate yestoday-price-media-width">
                    {{ yestodayPrice }}
                  </th>
                  <th class="text-end exchange-table__title text-truncate time-media-width">
                    {{ $t('basic.time') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="n in 3" :key="n" class="border-bottom">
                  <td class="exchange-table__item text-truncate">
                    <div class="skeleton skeleton-text"></div>
                  </td>
                  <td class="exchange-table__item">
                    <div class="skeleton skeleton-text"></div>
                  </td>
                  <td class="exchange-table__item">
                    <div class="skeleton skeleton-text"></div>
                  </td>
                  <td class="exchange-table__item text-center">
                    <div class="skeleton skeleton-text"></div>
                  </td>
                  <td class="exchange-table__item text-end">
                    <div class="skeleton skeleton-text"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </client-only>
      </div>
      <button
        aria-label="Gold Center SHOW MORE"
        class="btn btn-outline shadow-none rounded-0 d-inline-flex align-items-center justify-content-center w-100 primary-color-background text-white"
        @click="goToBuyerAccount"
      >
        {{ $t('basic.seeMore') }}
      </button>
    </div>
  </section>
</template>

<script>
import CurrencyRates from '~/core/mixins/currencyRates'
import Time from '~/core/mixins/time'

export default {
  name: 'RatesAndCurrencies',

  mixins: [CurrencyRates, Time],
  data () {
    return {
      yestodayPrice: '+/-',
      isSmaillScreen: false
    }
  },
  async mounted () {
    if (process.browser) {
      this.isSmaillScreen = window.innerWidth < 496
    }
  },
  methods: {
    goToBuyerAccount () {
      this.$router.push({ path: this.localePath('/buyer-account') })
    }
  }
}
</script>

<style scoped>
.pre-fix {
  display: none;
  justify-content: center;
  align-items: center;
  background: #d9c274;
  width: 60%;
  height: 27px;
  color: #00323f;
  margin-left: auto;
}

.first-block-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  justify-content: end !important;
}

@media all and (max-width: 575px) {
  .pre-fix {
    display: flex;
  }
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 5s infinite;
  border-radius: 4px;
  display: inline-block;
  height: 16px;
  width: 100%;
}

.skeleton-text {
  height: 16px;
  width: 60%;
  margin: 0.5em 0;
}

.mobile-work_hours {
  display: flex;
  justify-content: center;
  margin: -4px auto 8px;
}
.table-container {
  display: table !important;
  width: 100%;
}
.metal-media-width {
  width: 28% !important;
}

.phone__item__link {
  font-size: 15px;
  font-weight: 400;
}

.rates-title span.button {
  padding: 2.5px 4px;
  background-color: #D8C273;
}

.ellipsis-mode .current-data {
  font-size: 15px;
  font-weight: 400;
  margin-left: 15px;
}

@media all and (max-width: 991.9px) {
  .table-container {
    display: none !important;
  }
  .metal-media-width {
    width: 28% !important;
  }
  .buy-media-width,
  .sel-media-width {
    width: 16%;
  }
}
@media(max-width:500px) {
  [lang="ru-RU"] .buy-media-width div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 3em;
  }
  [lang="ru-RU"] .sel-media-width div,
  [lang="am-AM"] .sel-media-width div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 4em;
  }
}
</style>
