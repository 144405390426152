import { mapActions } from 'vuex'
import moment from 'moment'
import { purityTypes } from '~/core/utils/BuyerAccount'

const chunk = (array, size = 1) => {
  const chunkedValues = []
  for (let i = 0; i < array.length; i += size) {
    const group = array.slice(i, i + size)
    chunkedValues.push(group)
  }
  return chunkedValues
}

export default {
  async mounted () {
    await this.getInformation()
    await this.getyesterdayrates()
    await this.getUserDiscount()
    this.rateInterval = setInterval(() => {
      this.getInformation()
    }, 10000)
  },
  beforeDestroy () {
    if (this.ws) {
      this.ws.close()
      this.ws = null
    }
    clearInterval(this.rateInterval)
    if (process.browser) {
      window.removeEventListener('resize', this.handleResize)
    }
  },
  data () {
    return {
      tableData: [],
      discount: 0,
      purityTypes,
      localStatus: '',
      localGoldData: [],
      localSilverData: [],
      messages: null,
      yesterdayRates: null,
      isProcessing: false
    }
  },
  watch: {
    async currentUser (newValue) {
      await this.getUserDiscount()
      this.getInformation()
    }
  },
  methods: {
    ...mapActions({
      local: 'buyerAccount/dashboard/local'
    }),
    connectWebSocket () {
      this.ws = new WebSocket('wss://api.goldcenter.am/v1/rate/vue-websocket')
      this.isProcessing = false

      this.ws.onmessage = (event) => {
        if (this.isProcessing) { return }

        try {
          if (event && event.data) {
            this.messages = JSON.parse(JSON.parse(event.data).lrs)
          }

          // if (this.messages && this.messages.length > 0) {
          //   if (this.tableData && this.tableData.length) {
          //     // if(Math.abs(this.tableData[0][0].sell - this.messages[0].sell) < 1.5){
          //     //   this.getRates(JSON.parse(JSON.stringify(this.messages)))
          //     // }
          //     this.getRates(this.messages)
          //   } else {
          //     this.getRates(JSON.parse(JSON.stringify(this.messages)))
          //   }
          // }

          if (this.messages && this.messages.length > 0) {
            this.getRates(this.messages)
          }
        } catch (error) {
          console.log('Error processing message:')
        } finally {
          this.isProcessing = false
        }
      }
      // Handle WebSocket errors
      this.ws.onerror = () => {
        this.messages = null
      }
      // Handle WebSocket connection close
      this.ws.onclose = () => {
        this.messages = null
      }
    },
    async getyesterdayrates () {
      try {
        const rates = await this.$axios.$get('/rate/local-yesterday')
        if (rates) {
          this.yesterdayRates = rates
        }
      } catch (error) {
        console.error('Error fetching pending orders:', error)
      }
    },
    async getUserDiscount () {
      if (!this.currentUser) {
        this.discount = 0
        return
      }
      try {
        const ugroup = await this.$axios.$get('/preorder/prices', {
          params: {
            user_id: this.currentUser.user_id
          }
        })
        if (ugroup) {
          this.discount = ugroup.discount
        }
      } catch (error) {
        console.error('Error fetching pending orders:', error)
      }
    },
    goToBuyerAccount () {
      if (!this.currentUser) {
        return this.$store.commit('ui/setModal', 'auth')
      }
      this.$router.push({ path: this.localePath('/buyer-account') })
    },

    async getInformation () {
      if (!this.messages) {
        await this.getRates()

        if (this.ws) {
          this.ws.close()
          this.ws = null
        }

        this.connectWebSocket()
      } else {
        this.messages = null
      }
    },

    async getRates (params = null) {
      let data = []
      if (!params) {
        const res = await this.local()
        data = res.data
        this.localStatus = res.status
      } else {
        data = params
      }

      let filtredData = []
      const filtredDataSilver = []

      data.forEach((element) => {
        let newElement = { ...element }

        this.purityTypes.forEach((purityElem) => {
          if (purityElem.id === newElement.id) {
            newElement = {
              ...purityElem,
              ...newElement
            }
          }
        })
        if (newElement.id === 1 || newElement.id === 2) {
          newElement.sell = this.customRound(newElement.sell)
          newElement.buy = this.customRound(newElement.buy)
          newElement.sell -= this.discount
        }

        const date = new Date()
        newElement.updated_at = date.toLocaleTimeString('en-GB', {
          hour12: false
        })

        if (newElement.id === 13 || newElement.id === 14) {
          filtredDataSilver.push(newElement)
        } else {
          filtredData.push(newElement)
        }
      })

      const element = filtredData.find(e => e.id === 7)
      const element585 = filtredData.find(e => e.id === 8)

      if (element || element585) {
        filtredData = filtredData.filter(e => element.id !== e.id)
      }

      if (this.isMobile) {
        filtredData.splice(2, 0, element585)
        this.tableData = chunk(filtredData, 3).slice(0, 1)
        this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
      } else {
        filtredData.splice(2, 0, element585)
        this.tableData = chunk(filtredData, 3).slice(0, 1)
        this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
      }
      if (process.browser) {
        this.resizeWindow = window.addEventListener('resize', () => {
          if (this.isMobile) {
            filtredData.splice(2, 0, element585)
            this.tableData = chunk(filtredData, 3).slice(0, 1)
            this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
          } else {
            filtredData.splice(2, 0, element585)
            this.tableData = chunk(filtredData, 3).slice(0, 1)
            this.localSilverData = chunk(filtredDataSilver, 1).slice(0, 1)
          }
        })
      }
    },

    customRound (value) {
      return Math.round(value / 0.05) * 0.05
    },
    renderYesterdayPrice (rateId, tPrice, yPrice) {
      yPrice = this.yesterdayRates ? this.yesterdayRates.find(r => r.rate_id === rateId).yesterday_sell_value : yPrice
      if (this.messages && this.messages.length) {
        const result = tPrice - yPrice === 0 ? 0 : (tPrice - yPrice).toFixed(2)
        const isNegative = result <= 0
        return {
          price: isNegative ? result : '+' + result,
          isNegative
        }
      }
      const result = tPrice - yPrice === 0 ? 0 : (tPrice - yPrice).toFixed(2)
      const isNegative = result <= 0
      return {
        price: isNegative ? result : '+' + result,
        isNegative
      }
    },
    isClosed () {
      return this.localStatus === 'close'
    },
    formatData (data) {
      return moment(data).format('HH:MM:SS')
    },
    renderCurrentData () {
      return moment().format('YYYY-MM-DD')
    }
  }
}
